import React from 'react';
import './App.css';

function App() {
  return (
    <main className="App">
      <section className="px-8  bg-gray-800  flex  h-screen items-center">
        <div className="container mx-auto flex overflow-hidden items-center flex-col md:flex-row  relative">
            <div className="w-full md:w-2/3 md:pr-8">
              <h1 className="text-center md:text-left text-white text-3xl md:text-5xl lg:text-7xl font-bold max-w-prose ">I'm Joe, a<br /><span class="text-teal-400">Full Stack</span> Developer</h1>
              <div className="text-center md:text-left mb-8 md:mb-0"><a href="mailto:talk@josephman.co.uk" className="block text-white mt-8">Talk with me: talk@josephman.co.uk</a></div>
            </div>
            <div className="w-full md:w-1/3">
                <div className="w-full rounded-full bg-cover bg-center relative " style={{ padding: "50%", backgroundImage: `url("https://josephman.co.uk/wp-content/uploads/2022/09/joe.webp")`}}>
                </div>
            </div>
        </div>
      </section>
    </main>
  );
}

export default App;
